@import "./include/animate.css";
@import "./include/bootstrap/bootstrap";
@import "./include/owl.carousel.min.css";
@import "./include/jquery.scrollbar.css";
@import "./include/jssocials.css";
@import "./include/lightgallery.min.css";
@import "./include/select2.min.css";
@import "./font-awesome.min.css";
@import "./include/jquery.datetimepicker.css";
@import "./include/bootstrap-datepicker.min.css";
@import "./include/slick.css";